.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__img {
    background: url(../../profile_pic.JPG);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    border-radius: 25%;
    align-self: center;
    /* animation: profile__animate 8s ease-in-out infinite 1s; */
}

/* @keyframes profile__animate {
    0% {
        border-radius: 20% 30% 15% 30%/20% 25% 30% 15%
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
    }
    100% {
        border-radius: 20% 30% 15% 30%/20% 25% 30% 15%
    }
} */

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover{
    color: var(--title-color-dark);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__hand {
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before {
    position: absolute;
    content: "";
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__hand {
        width: 26px;
        height: 26px;
    }

    .home__suitable {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home__img {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 768px) {

    .home__img {
        background: url(../../profile_pic-min.JPG);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 250px;
        height: 250px;
    }

    .home__content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .home__data {
        grid-column: 1/3;
    }

    .section {
        padding: 2rem 0 4rem;
    }

    .home__img {
        order: initial;
        justify-self: initial;
    }

}

@media screen and (max-width: 576px) {

    .home__img {
        background: url(../../profile_pic-min.JPG);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 200px;
        height: 200px;
    }

}

@media screen and (max-width: 350px) {

    :root {
        --big-font-size: 2.25rem;
    }

    .home__img {
        background: url(../../profile_pic-min.JPG);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        width: 150px;
        height: 150px;
    }

    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }

}